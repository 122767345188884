import {type OmitStrict} from 'type-zoo'
import LinkWrapper, {
  type LinkWrapperProps,
} from '~/design-system/foundations/LinkWrapper'
import {cn} from '~/design-system/utils'

type LinkOverlayProps = OmitStrict<LinkWrapperProps, 'id'>

export function LinkOverlay({
  className,
  analyticsEventName,
  ...props
}: LinkOverlayProps) {
  return (
    <LinkWrapper
      data-analytics-event-name={analyticsEventName}
      {...props}
      className={cn(
        className,
        'static before:absolute before:left-0 before:top-0 before:z-0 before:block before:h-full before:w-full before:cursor-pointer'
      )}
      id="link-overlay"
    />
  )
}

export const LinkBox = ({ref, ...props}: React.ComponentPropsWithRef<'div'>) => {
  const {children, className, ...rest} = props
  return (
    <div
      {...rest}
      ref={ref}
      className={cn(
        className,
        'relative [&_a[href]:not(#link-overlay)]:relative [&_a[href]:not(#link-overlay)]:z-[1]'
      )}
    >
      {children}
    </div>
  )
}
