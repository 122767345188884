/**
 * https://www.chromatic.com/docs/ignoring-elements
 *
 * Sometimes a component’s appearance changes every render or contains content like video
 * and animation that is impossible to test consistently. This will trigger visual changes
 * even when the component code hasn’t changed. Ignore DOM elements to tell
 * Chromatic to skip them when looking for changes.
 *
 * Also, this will circumvent postcss's appending hashes to classnames
 */
export const CHROMATIC_IGNORE_CLASSNAME = 'chromatic-ignore'
